import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

export const Header = ({ isMobile }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#0A3D62', padding: isMobile ? '20px': '' }}>
      <Toolbar style={{ flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between' }}>
        <IconButton 
          edge="start" 
          color="inherit" 
          aria-label="menu" 
          style={{ 
            marginRight: isMobile ? 0 : '20px',
            alignSelf: isMobile ? 'flex-start' : 'auto'
          }}
          onClick={handleMenuToggle}
        >
          <MenuIcon />
        </IconButton>
        {(!isMobile || menuOpen) && (
          <img src="/c-encode-white.png" alt="C-Encode Logo" style={{ width: '100px', marginBottom: isMobile ? '10px' : '0' }} />
        )}
        {(!isMobile || menuOpen) && (
          <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center' }}>
            <Button color="inherit" href="/">Home</Button>
            <Button color="inherit" href="/faq">FAQ</Button>
            <Button 
              href="mailto:info@c-encode.com" 
              style={{ 
                backgroundColor: 'rgba(255, 107, 53)', 
                color: 'white', 
                margin: isMobile ? '10px 0' : '0 15px',
                padding: '6px 16px', 
                borderRadius: '4px'
              }}
            >
              Book a Demo
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};